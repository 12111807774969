import React, { useState, useEffect, useRef } from 'react';
import { Mic, MicOff } from 'lucide-react';

const VoiceSearch = ({ onTranscription }) => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  useEffect(() => {
    if (transcript) {
      onTranscription(transcript);
      setTranscript('');
    }
  }, [transcript, onTranscription]);

  const startListening = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: 'audio/wav' });
        await sendAudioToDeepgram(audioBlob);
        chunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setIsListening(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopListening = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setIsListening(false);
    }
  };

  const sendAudioToDeepgram = async (audioBlob) => {
    const DEEPGRAM_API_KEY = process.env.REACT_APP_DEEPGRAM_API_KEY;
    try {
      const response = await fetch('https://api.deepgram.com/v1/listen', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${DEEPGRAM_API_KEY}`,
          'Content-Type': 'audio/wav'
        },
        body: audioBlob
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.results && data.results.channels && data.results.channels[0].alternatives) {
        const transcription = data.results.channels[0].alternatives[0].transcript;
        setTranscript(transcription);
      } else {
        console.error('Unexpected response structure:', data);
      }
    } catch (error) {
      console.error('Error sending audio to Deepgram:', error);
    }
  };

  return (
    <button
      className={`voice-search-button ${isListening ? 'listening' : ''}`}
      onClick={isListening ? stopListening : startListening}
    >
      {isListening ? <MicOff size={24} /> : <Mic size={24} />}
    </button>
  );
};

export default VoiceSearch;