import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useMsal } from "@azure/msal-react";
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';

function Login() {
  const { login, error, setError } = useAuth();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      await login('google', credentialResponse.credential);
      navigate('/chat');
    } catch (error) {
      console.error('Google login failed:', error);
      // Error is already set in AuthContext
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      const response = await instance.loginPopup({
        scopes: ["user.read"],
        prompt: "select_account"
      });
      await login('microsoft', response.accessToken);
      navigate('/chat');
    } catch (error) {
      console.error('Microsoft login failed:', error);
      // Error is already set in AuthContext
    }
  };

  return (
    <div className="login-container">
      <h1>Welcome to AI Chat</h1>
      {error && <div className="error-message">{error}</div>}
      <div className="login-buttons">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={() => {
            console.log('Google Login Failed');
            setError('Google Login Failed');
          }}
          useOneTap
        />
        <button onClick={handleMicrosoftLogin} className="microsoft-login-button">
          <img src="/microsoft-logo.png" alt="Microsoft logo" className="microsoft-logo" />
          Sign in with Microsoft
        </button>
      </div>
    </div>
  );
}

export default Login;